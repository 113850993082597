import { render, staticRenderFns } from "./404.vue?vue&type=template&id=00fd9c1a&scoped=true&"
var script = {}
import style0 from "./404.vue?vue&type=style&index=0&id=00fd9c1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00fd9c1a",
  null
  
)

export default component.exports